<template>
    <!-- nav -->
    <nav class="nav">
        <div class="nav__wrap">
            <a href="#" class="nav__btn">
                <i class="icon icon icon-left">이전 페이지</i>
            </a>
            <p class="nav__item">
                <span class="nav__text"> 검색 </span>
            </p>
            <a href="#" class="nav__btn">
            </a>
        </div>
    </nav>
    <!-- //nav -->
    <div id="container" class="container">
        <div id="sub" class="sub quicksearch">
	        <!-- search-form -->
	        <section class="search-form">
		        <div class="box">
			        <div class="search-form__wrap">
				        <input type="text" class="ipt ipt--muted" value="역ㅇㅇㅇ삼">
				        <button type="submit" class="btn-search">
					        <i class="icon icon-search"></i>
				        </button>
			        </div>
		        </div>
	        </section>
	        <!-- category -->
	        <section class="listsearch">
		        <div class="listsearch__wrap">
			        <div class="listsearch__inner">
				        <div class="listsearch__box">
                            <span class="listsearch__icon">
                                <i class="icon icon-date"></i>
                            </span>
					        <input type="text" class="listsearch__ipt" value="11.11(목)~11.12(금)" readonly>
				        </div>
			        </div>
			        <div class="listsearch__inner">
				        <div class="listsearch__box">
                            <span class="listsearch__icon">
                                <i class="icon icon-member"></i>
                            </span>
					        <input type="text" class="listsearch__ipt" value="성인 2명, 아동 2명" readonly>
				        </div>
			        </div>
		        </div>
	        </section>
            <!-- content -->
            <section class="content">
	            <div class="box">
		            <div class="menus">
			            <div class="menus__wrap">
				            <!-- [D] 활성화된 지역에 active 클래스 추가 -->
				            <!-- [REST] 지역 분류 관리자 페이지에서 설정 -->
				            <div class="menus__box">
					            <div class="menus__text active">모텔</div>
					            <div class="menus__text">호텔</div>
					            <div class="menus__text">펜션·풀빌라</div>
					            <div class="menus__text">리조트</div>
					            <div class="menus__text">캠핑/글램핑</div>
					            <div class="menus__text">게하</div>
					            <div class="menus__text">한옥</div>
				            </div>
			            </div>
		            </div>
	            </div>
                <div class="quicksearch__noresult">
                    <div class="textbox">
                        <div class="textbox__icon">
                            <img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
                        </div>
                        <h3 class="textbox__title">찾으시는 결과가 없나요?</h3>
                    </div>
                </div>
                <!-- recommendation -->
                <div class="row">
                    <!-- style -->
                    <div class="style">
                        <div class="style__item">
                            <h3 class="style__title">
                                이런 검색어는 어떠세요?
                            </h3>
                            <ul class="style__list">
                                <li>
                                    <label>
                                        <a href="#" class="style__text">
                                            서울
                                        </a>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <a href="#" class="style__text">
                                            제주도
                                        </a>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <a href="#" class="style__text">
                                            부산
                                        </a>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <a href="#" class="style__text">
                                            여수
                                        </a>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <a href="#" class="style__text">
                                            수상스키
                                        </a>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <a href="#" class="style__text">
                                            전주
                                        </a>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <a href="#" class="style__text">
                                            한옥
                                        </a>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <a href="#" class="style__text">
                                            물놀이
                                        </a>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <a href="#" class="style__text">
                                            속초
                                        </a>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <!-- //content -->
        </div>
    </div>
</template>